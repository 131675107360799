import NewFooter from '../NewFooter/NewFooter';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import { EnercityFooterProps } from './types';

const EnercityFooter = ({ skewOverlap }: EnercityFooterProps) => {
  return (
    <NewFooter
      copyrightText="enercity AG"
      foundationSlot={
        <>
          <NewFooter.Link href="/impressum">Impressum</NewFooter.Link>
          <NewFooter.Link href="/datenschutz">Datenschutz</NewFooter.Link>
          <NewFooter.Link href="/seitenuebersicht">
            Seitenübersicht
          </NewFooter.Link>
          <NewFooter.Link href="https://service.enercity.de/kuendigung">
            Verträge hier kündigen
          </NewFooter.Link>
        </>
      }
      logoSlot={<ProjectLogo height={34} project="enercity" solidFill />}
      skewOverlap={skewOverlap}
      socialLinksSlot={
        <>
          <NewFooter.SocialLink
            href="https://www.facebook.com/enercity/"
            iconVariant="action/facebook"
            title="enercity auf Facebook folgen"
          />
          <NewFooter.SocialLink
            href="https://twitter.com/enercity"
            iconVariant="action/twitter"
            title="enercity auf Twitter folgen"
          />
          <NewFooter.SocialLink
            href="https://www.instagram.com/enercity_ag"
            iconVariant="action/instagram"
            title="enercity auf Instagram folgen"
          />
          <NewFooter.SocialLink
            href="https://www.xing.com/pages/enercity"
            iconVariant="action/xing"
            title="enercity auf Xing folgen"
          />
          <NewFooter.SocialLink
            href="https://www.kununu.com/de/enercity"
            iconVariant="action/kununu"
            title="enercity auf kununu folgen"
          />
          <NewFooter.SocialLink
            href="https://de.linkedin.com/company/enercity-ag"
            iconVariant="action/linkedin"
            title="enercity auf LinkedIn folgen"
          />
        </>
      }
    >
      <NewFooter.LinkDirectory masonry>
        <NewFooter.LinkDirectoryGroup title="Produkte">
          <NewFooter.Link href="/privatkunden/produkte/strom">
            Strom
          </NewFooter.Link>
          <NewFooter.Link href="/privatkunden/produkte/gas">Gas</NewFooter.Link>
          <NewFooter.Link href="/privatkunden/produkte/solar">
            Solar
          </NewFooter.Link>
          <NewFooter.Link href="/privatkunden/produkte/waerme">
            Wärme
          </NewFooter.Link>
          <NewFooter.Link href="/privatkunden/produkte/elektromobilitaet">
            Elektromobilität
          </NewFooter.Link>
          <NewFooter.Link href="/privatkunden/produkte/smarthome">
            SmartHome
          </NewFooter.Link>
          <NewFooter.Link href="/privatkunden/produkte/wasser">
            Wasser
          </NewFooter.Link>
          <NewFooter.Link href="/privatkunden/produkte/hausanschluss">
            Hausanschluss
          </NewFooter.Link>
          <NewFooter.Link href="/privatkunden/produkte/telekommunikation">
            Telekommunikation
          </NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <NewFooter.LinkDirectoryGroup title="Vorteile">
          <NewFooter.Link href="/vorteile/plus">enercity+</NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <NewFooter.LinkDirectoryGroup title="Services">
          <NewFooter.Link href="https://helpcenter.enercity.de/hc/de">
            FAQ - Fragen & Antworten
          </NewFooter.Link>
          <NewFooter.Link href="/privatkunden/service/vertragsaenderung">
            Vertragsänderung
          </NewFooter.Link>
          <NewFooter.Link href="/privatkunden/service/energiesparen">
            Energie sparen
          </NewFooter.Link>
          <NewFooter.Link href="/privatkunden/service/energieausweis">
            Energieausweis
          </NewFooter.Link>
          <NewFooter.Link href="https://helpcenter.enercity.de/hc/de/articles/115004266629-Wie-teile-ich-enercity-meinen-Zählerstand-mit">
            Zählerstand und Abschlag
          </NewFooter.Link>
          <NewFooter.Link href="/login">
            Bankverbindung mitteilen
          </NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <NewFooter.LinkDirectoryGroup title="Über uns">
          <NewFooter.Link href="/konzern">Konzern</NewFooter.Link>
          <NewFooter.Link href="/newsroom">Newsroom</NewFooter.Link>
          <NewFooter.Link href="/karriere">Karriere</NewFooter.Link>
          <NewFooter.Link href="/konzern/investor-relations">
            Investor Relations
          </NewFooter.Link>
          <NewFooter.Link href="/konzern/nachhaltigkeit">
            Nachhaltigkeit
          </NewFooter.Link>
          <NewFooter.Link href="/waermewende">Wärmewende</NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <NewFooter.LinkDirectoryGroup title="Magazin">
          <NewFooter.Link href="/magazin/deine-stadt">
            #deine Stadt
          </NewFooter.Link>
          <NewFooter.Link href="/magazin/unsere-welt">
            #unsere Welt
          </NewFooter.Link>
          <NewFooter.Link href="/magazin/mein-leben">
            #mein Leben
          </NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <NewFooter.LinkDirectoryGroup title="Info">
          <NewFooter.Link href="https://www.enercity.de/myenercity/forms/login/index.jsx">
            Extranet
          </NewFooter.Link>
          <NewFooter.Link href="/konzern/einkauf">Einkauf</NewFooter.Link>
          <NewFooter.Link href="/downloads">Downloads</NewFooter.Link>
          <NewFooter.Link href="/newsletter">Newsletter</NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>
      </NewFooter.LinkDirectory>
    </NewFooter>
  );
};

export default EnercityFooter;
