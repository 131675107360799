import type { ProjectName } from '@/config';
import type { HeaderLink, HeaderVariant } from '@/hooks/useHeader/types';
import { LegacyHeaderVariant } from './consts';
import type { LegacyHeaderContents } from './types';

export const convertProjectName = (
  variant: LegacyHeaderVariant
): ProjectName => {
  switch (variant) {
    case LegacyHeaderVariant.BusinessAccounts:
    case LegacyHeaderVariant.Gewerbekunden:
    case LegacyHeaderVariant.GWK:
    case LegacyHeaderVariant.Job:
    case LegacyHeaderVariant.KeyAccounts:
    case LegacyHeaderVariant.Konzern:
    case LegacyHeaderVariant.Privatkunde:
      return 'enercity';
    case LegacyHeaderVariant.MeinHGas:
      return 'mein-h-gas';
    default:
      return variant.toLowerCase() as ProjectName;
  }
};

export const convertVariant = (variant: LegacyHeaderVariant): HeaderVariant => {
  switch (variant) {
    case LegacyHeaderVariant.Job:
      return 'career';
    case LegacyHeaderVariant.Privatkunde:
      return 'enercity';
    case LegacyHeaderVariant.Konzern:
      return 'enercity-com';
    case LegacyHeaderVariant.GWK:
    case LegacyHeaderVariant.BusinessAccounts:
    case LegacyHeaderVariant.Gewerbekunden:
    case LegacyHeaderVariant.KeyAccounts:
      return 'enercity-gwk';
    case LegacyHeaderVariant.MeinHGas:
      return 'mein-h-gas';
    default:
      return variant.toLowerCase() as ProjectName;
  }
};

export const createPageHeaderLink = ({
  props: link,
}: LegacyHeaderContents): HeaderLink => {
  if (link.link === 'page') {
    return {
      isInternalUrl: true,
      title: link.title,
      url: link.page,
    };
  }

  if (link.link === 'anchor') {
    return {
      isInternalUrl: false,
      title: link.title,
      url: `#${link.anchor}`,
    };
  }

  return {
    isInternalUrl: false,
    title: link.title,
    url: link.url,
  };
};
